<template>
  <v-app>
    <router-view />
  </v-app>
</template>

<script lang="ts" setup>
import { useHead, type HeadObject } from '@vueuse/head'; // Import the correct type HeadObject
import { useRoute } from 'vue-router';
import { watch } from 'vue';

// Access the current route
const route = useRoute();

// Watch for route changes to dynamically update meta tags
watch(
    () => route.meta,
    (meta) => {
      if (meta) {
        useHead({
          title: meta.title as string || 'Nöjd Städ och Puts - Professionella Städtjänster',
          meta: meta.metaTags as HeadObject['meta'] || []
        });
      }
    },
    { immediate: true }
);
</script>
