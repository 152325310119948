import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withModifiers as _withModifiers, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-194f9d10"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "mt-2",
  style: {"font-size":"0.8rem"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_select = _resolveComponent("v-select")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_textarea = _resolveComponent("v-textarea")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_alert = _resolveComponent("v-alert")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createBlock(_component_v_container, { class: "pa-0" }, {
    default: _withCtx(() => [
      _createVNode(_component_v_card, { class: "pa-4 elevation-12" }, {
        default: _withCtx(() => [
          _createVNode(_component_v_form, {
            modelValue: _ctx.isFormValid,
            "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.isFormValid) = $event)),
            density: "compact",
            onSubmit: _withModifiers(_ctx.handleSubmit, ["prevent"])
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_select, {
                modelValue: _ctx.formData.serviceType,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formData.serviceType) = $event)),
                density: "compact",
                label: _ctx.$t('form.serviceTypes.title') + ' *',
                items: _ctx.translatedServiceTypes,
                rules: [_ctx.requiredRule],
                required: ""
              }, null, 8, ["modelValue", "label", "items", "rules"]),
              _createVNode(_component_v_text_field, {
                modelValue: _ctx.formData.date,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.formData.date) = $event)),
                density: "compact",
                label: _ctx.$t('form.date') + ' *',
                type: "date",
                rules: [_ctx.requiredRule],
                required: "",
                class: "mt-2"
              }, null, 8, ["modelValue", "label", "rules"]),
              _createVNode(_component_v_text_field, {
                modelValue: _ctx.formData.time,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.formData.time) = $event)),
                density: "compact",
                label: _ctx.$t('form.time') + ' *',
                type: "time",
                rules: [_ctx.requiredRule],
                required: "",
                class: "mt-2"
              }, null, 8, ["modelValue", "label", "rules"]),
              _createVNode(_component_v_text_field, {
                modelValue: _ctx.formData.name,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.formData.name) = $event)),
                density: "compact",
                label: _ctx.$t('form.name.title') + ' *',
                placeholder: _ctx.$t('form.name.description'),
                rules: [_ctx.requiredRule],
                required: "",
                class: "mt-2"
              }, null, 8, ["modelValue", "label", "placeholder", "rules"]),
              _createVNode(_component_v_text_field, {
                modelValue: _ctx.formData.email,
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.formData.email) = $event)),
                density: "compact",
                label: _ctx.$t('form.email.title') + ' *',
                type: "email",
                placeholder: _ctx.$t('form.email.description'),
                rules: [_ctx.requiredRule, _ctx.emailRule],
                required: "",
                class: "mt-2"
              }, null, 8, ["modelValue", "label", "placeholder", "rules"]),
              _createVNode(_component_v_text_field, {
                modelValue: _ctx.formData.phone,
                "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.formData.phone) = $event)),
                density: "compact",
                label: _ctx.$t('form.phone.title') + ' *',
                type: "tel",
                placeholder: _ctx.$t('form.phone.description'),
                rules: [_ctx.requiredRule],
                required: "",
                class: "mt-2"
              }, null, 8, ["modelValue", "label", "placeholder", "rules"]),
              _createVNode(_component_v_text_field, {
                modelValue: _ctx.formData.personalNumber,
                "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.formData.personalNumber) = $event)),
                density: "compact",
                label: _ctx.$t('form.personalNumber.title') + ' *',
                placeholder: _ctx.$t('form.personalNumber.description'),
                rules: [_ctx.requiredRule],
                required: "",
                class: "mt-2"
              }, null, 8, ["modelValue", "label", "placeholder", "rules"]),
              _createVNode(_component_v_text_field, {
                modelValue: _ctx.formData.address,
                "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.formData.address) = $event)),
                density: "compact",
                label: _ctx.$t('form.address.title') + ' *',
                placeholder: _ctx.$t('form.address.description'),
                rules: [_ctx.requiredRule],
                required: "",
                class: "mt-2"
              }, null, 8, ["modelValue", "label", "placeholder", "rules"]),
              _createVNode(_component_v_textarea, {
                modelValue: _ctx.formData.otherInformation,
                "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.formData.otherInformation) = $event)),
                density: "compact",
                label: _ctx.$t('form.other.title'),
                placeholder: _ctx.$t('form.other.description'),
                rows: "3",
                class: "mt-2"
              }, null, 8, ["modelValue", "label", "placeholder"]),
              _createVNode(_component_v_btn, {
                color: "primary",
                class: "mt-3",
                block: "",
                type: "submit",
                disabled: !_ctx.isFormValid
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('form.submit')), 1)
                ]),
                _: 1
              }, 8, ["disabled"]),
              (_ctx.alertMessage)
                ? (_openBlock(), _createBlock(_component_v_alert, {
                    key: 0,
                    type: _ctx.alertType,
                    class: "mt-3",
                    transition: "scale-transition"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.alertMessage), 1)
                    ]),
                    _: 1
                  }, 8, ["type"]))
                : _createCommentVNode("", true),
              _createElementVNode("p", _hoisted_1, " * - " + _toDisplayString(_ctx.$t('form.mandatoryMessage')), 1)
            ]),
            _: 1
          }, 8, ["modelValue", "onSubmit"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}