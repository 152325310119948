// src/i18n.ts
import { createI18n } from 'vue-i18n';
import en from './locales/en.yaml';
import sv from './locales/sv.yaml';

type MessageSchema = typeof en;

// Load YAML files
const messages = {
    en,
    sv,
};

// Create the i18n instance
const i18n = createI18n<[MessageSchema], 'en' | 'sv'>({
    legacy: false,
    locale: 'sv', // Set the default locale
    fallbackLocale: 'en', // Set fallback locale
    messages
});

export default i18n;
