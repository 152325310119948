import { createRouter, createWebHistory } from 'vue-router';
import HomeView from '@/views/HomeView.vue';
import { homeMeta } from "@/metaConfig";

const routes = [
    {
        path: '/',
        name: 'HomeView',
        component: HomeView,
        meta: homeMeta,
    },
    // other routes...
];

const router = createRouter({
    history: createWebHistory(process.env.VUE_APP_BASE_URL),
    routes,
});

export default router;
