<template>
  <v-app-bar
    color="primary"
    app
    dense
    elevate-on-scroll
  >
    <v-container fluid>
      <v-row
        align="center"
        justify="space-between"
      >
        <!-- Logo: Visible only on large screens -->
        <v-col
          cols="auto"
          class="d-none d-md-flex"
        >
          <v-img
            src="@/assets/logo.svg"
            alt="Website Logo"
            :width="logoWidth"
            :height="logoHeight"
            class="align-center"
          />
        </v-col>

        <!-- Company Name -->
        <v-col
          cols="auto"
          class="text-center"
        >
          <h1 class="company-name">
            Nöjd Städ och Puts
          </h1>
        </v-col>

        <!-- Language Selector: Visible on large screens -->
        <v-col
          cols="auto"
          class="d-none d-md-flex align-center"
        >
          <v-btn
            icon="true"
            @click="setLanguage('sv')"
          >
            🇸🇪
          </v-btn>
          <v-btn
            icon="true"
            @click="setLanguage('en')"
          >
            🇬🇧
          </v-btn>
        </v-col>

        <!-- Hamburger Menu: Visible only on small screens -->
        <v-col
          cols="auto"
          class="d-flex d-md-none align-center"
        >
          <v-menu>
            <template #activator="{ props: menu }">
              <v-btn
                icon
                v-bind="mergeProps(menu)"
              >
                <v-icon>mdi-menu</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="setLanguage('sv')">
                🇸🇪 Svenska
              </v-list-item>
              <v-list-item @click="setLanguage('en')">
                🇬🇧 English
              </v-list-item>
            </v-list>
          </v-menu>
        </v-col>
      </v-row>
    </v-container>
  </v-app-bar>
</template>

<script lang="ts">
import { defineComponent, mergeProps, ref } from 'vue';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'Header',
  setup() {
    const { locale } = useI18n();
    const menu = ref(false);

    const logoHeight = ref(window.innerWidth >= 960 ? '60px' : '40px');
    const logoWidth = ref(window.innerWidth >= 960 ? '120px' : '80px');

    const setLanguage = (code: string) => {
      locale.value = code;
      console.log(`Language selected: ${code}`);
    };

    return {
      logoHeight,
      logoWidth,
      setLanguage,
      menu,
    };
  },
  methods: { mergeProps },
});
</script>

<style scoped>
/* Company name styling */
.company-name {
  font-size: 1.5rem;
  font-weight: bold;
  color: white;
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  letter-spacing: 1px;
}

/* Additional styling */
.v-app-bar {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.v-btn {
  background-color: rgba(255, 255, 255, 0.15);
  border-radius: 50%;
}

.v-btn:hover {
  background-color: rgba(255, 255, 255, 0.3);
}

.v-menu__content {
  min-width: 150px;
}

.v-list-item {
  cursor: pointer;
}
</style>