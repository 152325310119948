// src/metaConfig.ts

export const homeMeta = {
    title: 'Nöjd Städ och Puts - Professionella Städtjänster',
    metaTags: [
        // Open Graph meta tags
        { property: 'og:title', content: 'Nöjd Städ och Puts - Professionella Städtjänster' },
        { property: 'og:description', content: 'Välkommen till vår professionella städtjänst, där vi är dedikerade till att upprätthålla renheten och komforten i dina utrymmen.' },
        { property: 'og:type', content: 'website' },
        { property: 'og:url', content: 'https://nojdstad.com' }, // Update with your actual URL
        { property: 'og:locale', content: 'sv_SE' },
        { property: 'og:site_name', content: 'Nöjd Städ och Puts' },
        { property: 'og:image:alt', content: 'Nöjd Städ och Puts - Professionella Städtjänster' },
        { property: 'og:image:type', content: 'image/png' },
        // Primary image for most social media sites
        { property: 'og:image', content: '/images/social-image-1200x630.png' },
        { property: 'og:image:width', content: '1200' },
        { property: 'og:image:height', content: '630' },

        // Secondary image for LinkedIn
        { property: 'og:image', content: '/images/social-image-600x315.png' },
        { property: 'og:image:width', content: '600' },
        { property: 'og:image:height', content: '315' },

        // Additional Open Graph image for Slack
        { property: 'og:image', content: '/images/slack-image-512x512.png' },
        { property: 'og:image:width', content: '512' },
        { property: 'og:image:height', content: '512' },

        // Twitter card meta tags
        { name: 'twitter:card', content: 'summary_large_image' },
        { name: 'twitter:title', content: 'Nöjd Städ och Puts - Professionella Städtjänster' },
        //eslint-disable-next-line max-len
        { name: 'twitter:description', content: 'Välkommen till vår professionella städtjänst, där vi är dedikerade till att upprätthålla renheten och komforten i dina utrymmen.' },
        { name: 'twitter:image', content: '/images/twitter-image-1200x628.png' },

        // Apple touch icon
        { name: 'apple-touch-icon', content: '/images/apple-touch-icon-180x180.png' },

        // Favicon
        { name: 'icon', href: '/images/favicon-16x16.png', sizes: '16x16', type: 'image/png' },
        { name: 'icon', href: '/images/favicon-32x32.png', sizes: '32x32', type: 'image/png' },
        { name: 'icon', href: '/images/favicon-64x64.png', sizes: '64x64', type: 'image/png' },
        { name: 'icon', href: '/images/favicon-128x128.png', sizes: '128x128', type: 'image/png' },

        // General meta tags
        { name: 'description', content: 'Välkommen till vår professionella städtjänst, där vi är dedikerade till att upprätthålla renheten och komforten i dina utrymmen.' },
        { name: 'keywords', content: 'Städtjänster, Flyttstädning, Fönsterstädning, Hemstädning, Kontorsstädning, Stockholm, Professionell Städning' },
        { name: 'robots', content: 'index,follow' }
    ]
};


// more configurations for other pages...
