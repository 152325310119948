import { createApp } from 'vue'
import { createPinia } from 'pinia';
import App from './App.vue'
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'
import i18n from "@/i18n";
import router from "@/router";
import { createHead } from '@vueuse/head';

loadFonts().then(() => {
    // The app will not be mounted until the fonts are loaded
}).catch((error) => {
    console.error('Failed to load fonts:', error);
});

// Create the head instance
const head = createHead();

const pinia = createPinia();

createApp(App)
    .use(router)
    .use(vuetify)
    .use(pinia)
    .use(i18n)
    .use(head)
    .mount('#app')

// Set the default title
document.title = "Nöjdstäd";