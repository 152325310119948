// src/store/formData.ts
import { defineStore } from 'pinia';
import { useI18n } from "vue-i18n";

export const useFormDataStore = defineStore('formData', {
    state: () => ({
        // Sample data
        serviceTypes: [
            'form.serviceTypes.campus1Room',
            'form.serviceTypes.campus2Rooms',
            'form.serviceTypes.campus3Rooms',
            'form.serviceTypes.windowCleaning',
            'form.serviceTypes.homeCleaning',
            'form.serviceTypes.moveOutCleaning',
            'form.serviceTypes.officeCleaning',
            'form.serviceTypes.homeVisit'
        ],
    }),
    getters: {
        translatedServiceTypes: (state) => {
            const { t } = useI18n(); // Access the i18n instance
            return state.serviceTypes.map((key) => t(key));
        },
    },
});