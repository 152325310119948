<template>
  <v-container class="pa-0">
    <v-card class="pa-4 elevation-12">
      <v-form
        v-model="isFormValid"
        density="compact"
        @submit.prevent="handleSubmit"
      >
        <v-select
          v-model="formData.serviceType"
          density="compact"
          :label="$t('form.serviceTypes.title') + ' *'"
          :items="translatedServiceTypes"
          :rules="[requiredRule]"
          required
        />

        <v-text-field
          v-model="formData.date"
          density="compact"
          :label="$t('form.date') + ' *'"
          type="date"
          :rules="[requiredRule]"
          required
          class="mt-2"
        />

        <v-text-field
          v-model="formData.time"
          density="compact"
          :label="$t('form.time') + ' *'"
          type="time"
          :rules="[requiredRule]"
          required
          class="mt-2"
        />

        <v-text-field
          v-model="formData.name"
          density="compact"
          :label="$t('form.name.title') + ' *'"
          :placeholder="$t('form.name.description')"
          :rules="[requiredRule]"
          required
          class="mt-2"
        />

        <v-text-field
          v-model="formData.email"
          density="compact"
          :label="$t('form.email.title') + ' *'"
          type="email"
          :placeholder="$t('form.email.description')"
          :rules="[requiredRule, emailRule]"
          required
          class="mt-2"
        />

        <v-text-field
          v-model="formData.phone"
          density="compact"
          :label="$t('form.phone.title') + ' *'"
          type="tel"
          :placeholder="$t('form.phone.description')"
          :rules="[requiredRule]"
          required
          class="mt-2"
        />

        <v-text-field
          v-model="formData.personalNumber"
          density="compact"
          :label="$t('form.personalNumber.title') + ' *'"
          :placeholder="$t('form.personalNumber.description')"
          :rules="[requiredRule]"
          required
          class="mt-2"
        />

        <v-text-field
          v-model="formData.address"
          density="compact"
          :label="$t('form.address.title') + ' *'"
          :placeholder="$t('form.address.description')"
          :rules="[requiredRule]"
          required
          class="mt-2"
        />

        <v-textarea
          v-model="formData.otherInformation"
          density="compact"
          :label="$t('form.other.title')"
          :placeholder="$t('form.other.description')"
          rows="3"
          class="mt-2"
        />

        <v-btn
          color="primary"
          class="mt-3"
          block
          type="submit"
          :disabled="!isFormValid"
        >
          {{ $t('form.submit') }}
        </v-btn>

        <v-alert
          v-if="alertMessage"
          :type="alertType"
          class="mt-3"
          transition="scale-transition"
        >
          {{ alertMessage }}
        </v-alert>

        <p
          class="mt-2"
          style="font-size: 0.8rem;"
        >
          * - {{ $t('form.mandatoryMessage') }}
        </p>
      </v-form>
    </v-card>
  </v-container>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import { useFormDataStore } from '@/store/formData';
import { useI18n } from 'vue-i18n';
import axios from 'axios';

export default defineComponent({
  name: 'FormSection',
  setup() {
    const { t } = useI18n();
    const store = useFormDataStore();

    const formData = ref({
      serviceType: '',
      date: '',
      time: '',
      name: '',
      email: '',
      phone: '',
      personalNumber: '',
      address: '',
      otherInformation: '',
    });

    const isFormValid = ref(false);
    const alertMessage = ref('');
    const alertType = ref<'error' | 'success' | 'info' | 'warning' | undefined>(undefined);

    // Validation rules
    const requiredRule = (v: string) => !!v || 'This field is required';
    const emailRule = (v: string) =>
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(v) || 'E-mail must be valid';

    const handleSubmit = async () => {
      try {
        // Construct the message to be sent
        // eslint-disable-next-line max-len
        const message = `*Booking Request*\n\n*Service Type:* ${formData.value.serviceType}\n*Date:* ${formData.value.date}\n*Time:* ${formData.value.time}\n*Name:* ${formData.value.name}\n*Email:* ${formData.value.email}\n*Phone:* ${formData.value.phone}\n*Personal Number:* ${formData.value.personalNumber}\n*Address:* ${formData.value.address}\n*Other Information:* ${formData.value.otherInformation}`;

        // Prepare the data for the POST request
        const params = new URLSearchParams();
        params.append('message', message);

        // Send the POST request to your PHP backend
        const response = await axios.post('/api/v1/submit-booking.php', params, {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        });

        // Handle the response
        if (response.status === 200) {
          alertMessage.value = t('form.successMessage');
          alertType.value = 'success';

          // Clear the form after successful submission
          formData.value = {
            serviceType: '',
            date: '',
            time: '',
            name: '',
            email: '',
            phone: '',
            personalNumber: '',
            address: '',
            otherInformation: '',
          };
        } else {
          alertMessage.value = t('form.errorMessage');
          alertType.value = 'error';
        }
      } catch (error) {
        alertMessage.value = t('form.errorMessage');
        alertType.value = 'error';
      }

      // Clear the alert message after 5 seconds
      setTimeout(() => {
        alertMessage.value = '';
      }, 5000);
    };

    return {
      t,
      formData,
      translatedServiceTypes: store.translatedServiceTypes,
      handleSubmit,
      alertMessage,
      alertType,
      requiredRule,
      emailRule,
      isFormValid,
    };
  },
});
</script>

<style scoped>
.v-card {
  background-color: rgba(255, 255, 255, 0.95); /* Add some transparency */
}
</style>
