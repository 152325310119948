import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_col = _resolveComponent("v-col")!
  const _component_FormSection = _resolveComponent("FormSection")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_container = _resolveComponent("v-container")!
  const _component_Layout = _resolveComponent("Layout")!

  return (_openBlock(), _createBlock(_component_Layout, null, {
    default: _withCtx(() => [
      _createVNode(_component_v_container, {
        fluid: "",
        class: "fill-height pa-0"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_row, {
            id: "booking",
            class: "ma-0 fill-height"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_col, {
                cols: "12",
                md: "2"
              }),
              _createVNode(_component_v_col, {
                cols: "12",
                md: "3",
                class: "d-flex flex-column justify-center"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_FormSection)
                ]),
                _: 1
              }),
              _createVNode(_component_v_col, {
                cols: "12",
                md: "5",
                class: "d-flex flex-column justify-center text-center py-12 px-8"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_card, {
                    flat: "",
                    class: "pa-6",
                    style: {
              background: 'linear-gradient(to right, #6a11cb, #2575fc)',
              color: 'white',
              borderRadius: '12px',
            }
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_card_title, { class: "display-2 font-weight-bold text-uppercase" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('main.title')), 1)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_v_divider, {
                        color: "white",
                        class: "my-4"
                      }),
                      _createVNode(_component_v_card_text, { class: "subtitle-1" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('main.description')), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_v_col, {
                cols: "12",
                md: "2"
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}