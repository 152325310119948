<template>
  <v-footer
    app
    class="footer"
  >
    <v-container>
      <v-row>
        <!-- First Column: Contact Information -->
        <v-col
          cols="12"
          md="3"
        >
          <h3 class="footer-heading">
            {{ $t('footer.contactInformation') }}
          </h3>
          <p>
            <strong>{{ $t('footer.phone') }}:</strong> +46 739 679 801<br>
            <strong>{{ $t('footer.email') }}:</strong> info@nojdstad.se
          </p>
        </v-col>

        <!-- Second Column: Address -->
        <v-col
          cols="12"
          md="3"
        >
          <h3 class="footer-heading">
            {{ $t('footer.address') }}
          </h3>
          <p>
            {{ $t('footer.addressDetails1') }}
            <br>
            {{ $t('footer.addressDetails2') }}
          </p>
        </v-col>

        <!-- Third Column: Services -->
        <v-col
          cols="12"
          md="3"
        >
          <h3 class="footer-heading">
            {{ $t('footer.services') }}
          </h3>
          <ul class="footer-services">
            <li>{{ $t('form.serviceTypes.campus1Room') }}</li>
            <li>{{ $t('form.serviceTypes.campus2Rooms') }}</li>
            <li>{{ $t('form.serviceTypes.campus3Rooms') }}</li>
            <li>{{ $t('form.serviceTypes.windowCleaning') }}</li>
            <li>{{ $t('form.serviceTypes.homeCleaning') }}</li>
            <li>{{ $t('form.serviceTypes.moveOutCleaning') }}</li>
            <li>{{ $t('form.serviceTypes.officeCleaning') }}</li>
            <li>{{ $t('form.serviceTypes.homeVisit') }}</li>
          </ul>
        </v-col>

        <!-- Fourth Column: Logo -->
        <v-col
          cols="12"
          md="3"
          class="d-flex align-center justify-center"
        >
          <v-img
            src="@/assets/logo_name.svg"
            alt="Website Logo"
            :height="logoHeight"
          />
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';

export default defineComponent({
  name: 'Footer',
  setup() {
    const logoHeight = ref('300px');  // Adjust height as needed for the footer logo

    return {
      logoHeight,
    };
  },
});
</script>

<style scoped>
.footer {
  background-color: #b8b8b8; /* Light grey background */
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.footer-heading {
  font-size: 1.25rem;
  font-weight: bold;
  margin-bottom: 1rem;
  font-family: 'Montserrat', sans-serif;
}

.footer p,
.footer li {
  font-size: 0.9rem;
  margin-bottom: 0.5rem;
}

.footer-services {
  list-style: none;
  padding: 0;
}

.footer-services li {
  margin-bottom: 0.5rem;
}

.footer img {
  max-height: 100px;  /* Ensure the logo fits well */
}
</style>
