<template>
  <v-app>
    <Header />
    <v-main>
      <slot />
    </v-main>
    <Footer />
  </v-app>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';

export default defineComponent({
  name: 'Layout',
  components: {
    Header,
    Footer,
  },
});
</script>

<style scoped>
/* Add any specific styles for the layout here */
</style>
