<template>
  <Layout>
    <v-container
      fluid
      class="fill-height pa-0"
    >
      <v-row
        id="booking"
        class="ma-0 fill-height"
      >
        <v-col
          cols="12"
          md="2"
        />

        <!-- Left section for form -->
        <v-col
          cols="12"
          md="3"
          class="d-flex flex-column justify-center"
        >
          <FormSection />
        </v-col>

        <!-- Right section for text -->
        <v-col
          cols="12"
          md="5"
          class="d-flex flex-column justify-center text-center py-12 px-8"
        >
          <v-card
            flat
            class="pa-6"
            :style="{
              background: 'linear-gradient(to right, #6a11cb, #2575fc)',
              color: 'white',
              borderRadius: '12px',
            }"
          >
            <v-card-title class="display-2 font-weight-bold text-uppercase">
              {{ $t('main.title') }}
            </v-card-title>
            <v-divider
              color="white"
              class="my-4"
            />
            <v-card-text class="subtitle-1">
              {{ $t('main.description') }}
            </v-card-text>
          </v-card>
        </v-col>

        <v-col
          cols="12"
          md="2"
        />
      </v-row>
    </v-container>
  </Layout>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import Layout from '@/components/Layout.vue';
import FormSection from '@/components/FormSection.vue';

export default defineComponent({
  name: 'HomeView',
  components: {
    Layout,
    FormSection,
  },
});
</script>

<style scoped>
#booking {
  background-color: rgba(47, 103, 177, 0.6);
}

.v-card-title {
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.4);
}

.v-card-text {
  font-size: 1.125rem;
  line-height: 1.75rem;
}
</style>
